<template>
  <div class="join">
    <div class="login_bg">
      <img src="@/assets/images/login_bg.jpg">
    </div>
    <div class="baseBox smallBaseBox" style="height: 730px;overflow-y: scroll;top: 42%;">
      <div class="joinBox smallJoinBox" style="margin: 0;">
        <h1>이용약관동의</h1>
        <h3 style="max-width: 100%;">
          데모 서비스를 이용함에 있어 온라인상에서 제공한 개인정보가 철저히 보호를 받으며,<br/>
          모든 회원정보는 개인정보보호정책에 의해 보호받고 있음을 말씀드립니다.<br/><br/>
          가입을 원하시면 아래의 이용약관과 개인정보보호정책을 반드시 읽고 <span class="bold" style="font-size: 12px;">'동의'</span>버튼을 선택해주세요.
        </h3>
        <div class="form" v-for="(item, index) in checkList" :key="index">
          <div class="flexL terms-from" style="margin-bottom: 15px;">
            <h1>
              <input type="checkbox"
                     :id="item"
                     :value="item"
                     v-model="selectList"
                     :key="index"/>
              <label :for="item" :key="index + '1'"> {{ item }}(필수)</label>
            </h1>
            <div class="termsWrap">
              <div v-if="index == 0">
                <PrivacyPolicy />
              </div>
              <div v-if="index == 1">
                <TermsOfService />
              </div>
              <div v-if="index == 2">
                <LocationBasedServices />
              </div>
            </div>
          </div>
        </div>
        <h1>
          <input type="checkbox"  value="all" v-model="allSelected"/>
          <span style="font-size: 1.2rem;">위의 모든 약관에 동의합니다.</span>
        </h1>
        <div class="buttonWrap">
          <button class="point large joinBtn" @click="submit()">{{ $t("btn-sign-in") }}</button>
          <button class="cancel large cancelBtn" @click="$router.go(-1)">
            {{ $t("btn-cancel") }}
          </button>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<style>
.smallJoinBox > h1{font-size: 1.4rem;}
.smallJoinBox > h3{font-size: 1.2rem; color: #969696;margin-bottom: 20px !important;}
.terms-from > h1 > input{margin-right: 5px;}
.terms-from > h1 > label{font-size: 1.2rem !important; color: #969696; font-weight: 400;}
.form{margin: 0 0 20px 0 !important;}
.termsWrap > div span{font-size: 1.2rem !important;}
.buttonWrap > .joinBtn{background: #0080FF;border: 0;border-radius: 5px;}
.buttonWrap > .cancelBtn{border: 1px solid #ddd; color: #969696;}
</style>
<script>
// import sha256 from "js-sha256";
import { format } from "@/mixins/format";
import moment from "moment";
import Footer from "@/components/Footer";
import PrivacyPolicy from "../termsAndCondition/PrivacyPolicy";
import TermsOfService from "../termsAndCondition/TermsOfService";
import LocationBasedServices from "../termsAndCondition/LocationBasedServices";

import {
  fetchAccountList
} from "@/api/account";

export default {
  components: { Footer , PrivacyPolicy, LocationBasedServices, TermsOfService},
  mixins: [format],
  data() {
    return {
      moment: moment,
      visible: false,
      accountId: "",
      accountList: [],

      checkList: ["개인정보 처리방침", "서비스 이용약관", "위치기반 서비스 이용약관"],
      selectList: [],
    };
  },
  watch : {

  },
  mounted() {
    this.accountId = this.$route.params.accountId;
    this.getAccountList();
  },
  computed:{
    allSelected: {
      //getter
      get: function() {
        return this.checkList.length === this.selectList.length;
      },
      //setter
      set: function(e) {
        this.selectList = e ? this.checkList : [];
      },
    },
  },
  methods: {


    getAccountList() {
      fetchAccountList().then((res) => {
        this.accountList = res.data.data;
      });
    },
    submit() {
      if(this.checkList.length === this.selectList.length){
        this.$router.push(`/${this.accountId}/join`);
      } else{
        alert("가입을 원하시면 아래의 이용약관과 개인정보보호정책을 반드시 읽고 모두 '동의' 버튼을 선택해 주세요.")
      }
    },
  },
};
</script>
